import React from 'react';
//import Routes from './Routes'
import Home from './views/Home'
import Navigation from './components/Navigation'
import './styles/App.css';

function App() {
        //<Navigation />
  return (
    <div className="app">
      <div className="app-column">
        <Home />
      </div>
    </div>
  );
}
      //<div className="background"/>

export default App;
