import React from 'react'
//const seshKey = 'lkajshagosadifiasdfnalwoasdfhqn'
  //if (props.session) {
  //  if (window.sessionStorage.getItem(seshKey))
  //  return <div>{props.children}</div>
  //  window.sessionStorage.setItem(seshKey, true)
  //}

const Delay = props => {
  if (props.once) {
    const key = props.key
              ? props.key
              : 'delay-animation'
    const once = window[key]
    if (once) {
      return <div {...props}>{props.children}</div>
    }
    window[key] = true
  }
  const direction = props.direction 
                  ? props.direction 
                  : 'bottom'
  const delay = props.delay
              ? props.delay
              : '3'
  const duration = props.duration 
                 ? props.duration 
                 : '3'
  return ( 
    <div className={`
        vhs-${direction}
        vhs-delay-${delay}
        vhs-duration-${duration}
        delay-panel
      `}
      {...props}
    >
      {props.children}
    </div> 
  )
}
export default Delay

