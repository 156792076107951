import React from 'react'

const linkedinUrl = "https://www.linkedin.com/in/sam-weller-354469161/"

export default {
  linkedinUrl: linkedinUrl,
  whatIDo: () => (<h2>What I do for work</h2>),
  summary: () => (
    <>
      <p>I've been a Full Stack Software Engineer for the last 5 years.</p>
      <p>I &hearts; the JS community & ecosystem, and I'm a big fan of the world of serverless.</p>
    </>
  ),
  findMeOnLinkedin: () => (
    <h2>
      <a href={linkedinUrl} target="_blank">
      Find me on LinkedIn
      </a>
    </h2>
  ),
  letsConnect: () => (
    <>
      <p>If you need someone to build your new app or service, let's connect!</p>
      <p>If you need someone to migrate your app to scalable architecture, I can help with that.</p>
    </>
  ),
  skillsSummary: () => (
    <>
    <h2>
      What I can contribute
    </h2>
    <ul className="work-list">
      <li>
      Experience building highly available Single Page Applications and APIs supported by serverless infrastructure.
      </li>
      <li>
      Expertise in modern Javascript - ReactJS, NodeJS, ES6 - and Infrastructure as Code with Serverless Framework and AWS
      </li>
      <li>
      5 years of Full Stack experience acress monolithic applications and micro service environments.
      </li>
    </ul>
    </>
  ),
}
