import React from 'react'
import Delay from '../components/Delay'
import Mountains from '../components/drawings/Mountains'
import Sun from '../components/drawings/Sun'
import MeFace from '../components/MeFace'
import workContent from '../constants/workContent'
import simon from '../assets/simonx10.gif'


export default () =>
<>
<div
  className='home container'
>
  <header className="app-header home">
    <Delay
      delay={5}
      duration={4}
      once={false}
      key='home'
      style={{
        height: '100%'
      }}
    >
      <div className='hero'>
        <h2>Hey there,</h2>
        <h1>I'm Sam Weller</h1>
        <h3>a Software Engineer in Portland, OR</h3>
      </div>
    </Delay>
    <SimonPanel/>
  </header>
</div>
<header className="app-header work container">
  <WorkContent/>
</header>
</>

const Section = ({children, delay}) =>
  <div className={`work-section vhs-fade vhs-delay-${delay}`}>
    {children}
  </div>

const WorkContent = () =>
<>
  <Section delay={1}>
  {workContent.whatIDo()}
  {workContent.summary()}
  </Section>
  <Section delay={2}>
  {workContent.findMeOnLinkedin()}
  {workContent.letsConnect()}
  </Section>
  <Section delay={3}>
  {workContent.skillsSummary()}
  </Section>
  <Section delay={3}>
  <MeFace/>
  <br/>
  <br/>
  <Links/>
  <br/>
  <br/>
  </Section>
</>

const email = 'samueljwell@gmail.com'

const Links = () =>
<div style={{fontSize: 20}}>
  <a href={`mailto:${email}`}>{email}</a>
  <br/>
  <br/>
  <a target='_blank' href={workContent.linkedinUrl}>{workContent.linkedinUrl}</a>
</div>

//<Graphics/>
const SimonPanel = () =>
  <Delay
    delay={3}
    duration={3}
    once={false}
    key='home-simon'
  >
    <div style={{display: 'flex'}}>
      <img
        className='home simon'
        src={simon}
        alt='this is a cat'
      />
    </div>
  </Delay>

const Graphics = () =>
  <div style={{
      position: "absolute",
      top: "calc(100% - 400px)"
    }}>
    <Sun
      className="drawing sun"
      stroke="orange"
      style={{
        position: "fixed",
        width: "300px",
        left: "calc(50% - 100px)",
        top: "calc(100% - 310px)",
      }}
      l={0} t={0} w={400} h={600}
    />
    <Mountains
      className="drawing"
      stroke="lightblue"
      style={{
        position: "fixed",
        width: "300px",
        left: "calc(50% - 180px)",
        top: "calc(100% - 370px)",
      }}
      l={0} t={0} w={400} h={600}
    />
  </div>
